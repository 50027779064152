export const apiUrlPrefix =
  process.env.NODE_ENV === "production" ? "/v1" : "/api/v1";

/**
 * Function to check whether a request error is a network error or axios error
 * @param err {*}
 * @returns {boolean|boolean}
 */
const isNetworkError = err => {
  return !!err.isAxiosError && !err.response;
};

/**
 * Function for rendering the widgets from the components folder
 * @param view
 * @returns {function(): *}
 */
const loadComponent = view => {
  return () => import(`@/components/${view}.vue`);
};

export { loadComponent, isNetworkError };
